import { onDOMContentLoaded } from "tsi-common-react/src/utils/events";
import { clearSelectedClass, CURRENT_CLASS } from "./index";

const registerEventListeners_Tabs = () => {
    Array.from(document.querySelectorAll<HTMLElement>(".tabs")).forEach(
        (elem) => {
            Array.from(elem.getElementsByClassName("tab")).forEach((tab) => {
                const link = tab.getElementsByTagName("a")[0];

                if (link) {
                    link.addEventListener("click", (e) => {
                        e.preventDefault();
                        const tabID = link.parentElement
                            ? link.parentElement.dataset.key
                            : null;
                        const tabContent = elem.querySelector(
                            `.tab-content[data-key="${tabID}"]`,
                        );

                        if (
                            tabContent &&
                            link.parentElement &&
                            tabContent.parentElement
                        ) {
                            clearSelectedClass(elem, "tab");
                            clearSelectedClass(elem, "tab-content");
                            link.parentElement.classList.add(CURRENT_CLASS);
                            tabContent.classList.add(CURRENT_CLASS);
                        }
                    });
                }
            });
        },
    );
};

onDOMContentLoaded.on(() => {
    registerEventListeners_Tabs();
});
