import {
    ajax,
    CSRF_HEADER,
    getCSRFToken,
} from "tsi-common-react/src/utils/ajax";
import {
    trapFocus,
    focusElement,
} from "tsi-common-react/src/utils/keyboardFocus";

interface RetailerLocation {
    address1: string;
    address2: string;
    city: string;
    distance: string;
    name: string;
    phone: string;
    state: string;
    url: string;
    zip: string;
    lat: string;
    lng: string;
}

declare global {
    interface Window {
        lastLocationSearched: string;
    }
}

const surveyCell1URL = "/api/email-retail-locator-results";
// const surveyCell2URL = 'https://api-staging.promosissweeps.com/rest/tsi-2190/survey.php'; // staging
const surveyCell2URL =
    "https://api.promosissweeps.com/rest/tsi-2190/survey.php"; // prod

const surveyEnabled = true;
const surveyPrepopModePause = 50 * 1000; // seconds to pause in pre-pop mode
const surveyEmailModePause = 1 * 1000; // seconds to pause in email-only mode
const surveyCompletedAutoClose = 3; // seconds to wait before closing survey on final cell
const surveyLocatorResultsWait = 5; // seconds to wait for storeLocator results
const cookieLifetime = 4; // days
const cookieEmailTaken = "locator_survey_email_taken";
const cookieRatingTaken = "locator_survey_rating_taken";
const surveyPromosisBrand = "SF"; // TP for Tempur-Pedic, SF for Stearns & Foster, or SY for Sealy store locators.
const surveyPromosisAuth = "c78q87n0x44f";
const surveyCarouselElement = document.querySelector(".survey-carousel");
// const selectorZipForm = "section.retail-locator form";
const selectorCloseButton = ".modal-close > .modal-close__icon";
const selectorLocatorResults = ".locations > article";
window.lastLocationSearched = "";
const surveyDebugFlag = false;

const setCookie = (cname: string, cvalue: string, exdays: number) => {
    const d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    const expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
};

const getCookie = (cname: string) => {
    const name = cname + "=";
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(";");
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === " ") {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
};

const getModalInner = () => {
    return document.querySelector<HTMLElement>(".modal-inner");
};

const surveyDebug = (msg: unknown) => {
    if (typeof surveyDebugFlag === "undefined") {
        console.warn("surveyDebug(): surveyDebugFlag is undefined");
        return false;
    }
    if (surveyDebugFlag) {
        if (typeof msg !== "string") {
            console.log(msg);
        } else {
            console.log("survey: " + msg);
        }
    }
    return true;
};

// from http://stackoverflow.com/questions/46155/validate-email-address-in-javascript
const validateEmail = (email: string) => {
    const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
};

const countResults = () => {
    const resultCount = document.querySelectorAll(
        selectorLocatorResults,
    ).length;
    surveyDebug(
        "hasLocatorResults: countResults: storeLocator found " +
            resultCount.toString() +
            " results",
    );
    return resultCount;
};

const loadSurvey = async () => {
    const jQuery = (await import("jquery")).default;
    const Flickity = (await import("flickity")).default;

    let surveyHandlersSet = false;
    let flkty: Flickity | null = null;
    let surveyUserEmail = "";

    // action to close after a completed survey
    const closeSurvey = () => {
        surveyDebug("survey completed, saving.");
        setCookie(cookieEmailTaken, "y", cookieLifetime);
        surveyDebug("closing survey display");
        flkty?.destroy();
        jQuery(".store-locator-survey").hide();
    };

    // dismiss survey popup click handler
    const closeDismiss = () => {
        surveyDebug("survey dismissed");
        surveyDebug("removing survey");
        flkty?.destroy();
        jQuery(".store-locator-survey").hide();
    };

    const displaySurvey = () => {
        surveyDebug("displaying survey interface");
        jQuery(".store-locator-survey").show();

        initializeFlickity();
        // ensure survey click handlers are only set once
        if (!surveyHandlersSet) {
            bindClickHandlers();
            surveyHandlersSet = true;
        }
        const modalInner = getModalInner();
        if (modalInner) {
            trapFocus(modalInner);
        }
        focusElement("input#survey-cell1-email");
    };

    const initializeFlickity = () => {
        if (surveyCarouselElement) {
            flkty = new Flickity(surveyCarouselElement, {
                // options
                cellAlign: "left",
                contain: true,
                draggable: false,
                prevNextButtons: false,
                pageDots: false,
                adaptiveHeight: true,
            });
        }
    };

    const bindClickHandlers = () => {
        // 1st cell, "Send Results" button click handler
        jQuery("input#survey-cell1-submit").on("click", async (event) => {
            event.preventDefault();
            const emailField = jQuery("input#survey-cell1-email");

            // ensure email has been provided by user
            if (emailField.val() === "") {
                alert("Email address required.");
                return false;
            }

            // validate email address
            if (!validateEmail(emailField.val() as string)) {
                alert("Invalid email address, try again.");
                emailField.trigger("focus");
                return false;
            }

            // store email address in window scope as it will be needed in the next cell's submission as well
            surveyUserEmail = emailField.val() as string;

            surveyDebug(
                "bindClickhandlers(): cell1: submit: emailField.val VVVV: ",
            );
            surveyDebug(emailField.val());

            // DOM extraction: store data from first 3 locations
            const locations: RetailerLocation[] = [];

            // gather location store info from DOM elements
            for (let i = 0; i <= 2; i++) {
                surveyDebug("selected locations[] element: " + i.toString());

                surveyDebug(
                    "loc-addr: " +
                        jQuery(".locations article > .store-data > .loc-addr")
                            .eq(i)
                            .text(),
                );
                surveyDebug(
                    "loc-addr2: " +
                        jQuery(
                            ".locations > article > .store-data > .loc-addr2",
                        )
                            .eq(i)
                            .text(),
                );
                surveyDebug(
                    "loc-city: " +
                        jQuery(".locations > article > .store-data > .loc-city")
                            .eq(i)
                            .text(),
                );
                surveyDebug(
                    "loc-distance: " +
                        jQuery(
                            ".locations > article > .store-data > .loc-distance",
                        )
                            .eq(i)
                            .text(),
                );
                surveyDebug(
                    "loc-name: " +
                        jQuery(".locations > article > .store-data > .loc-name")
                            .eq(i)
                            .text(),
                );
                surveyDebug(
                    "loc-phone: " +
                        jQuery(
                            ".locations > article > .store-data > .loc-postal",
                        )
                            .eq(i)
                            .text(),
                );
                surveyDebug(
                    "loc-state: " +
                        jQuery(
                            ".locations > article > .store-data > .loc-state",
                        )
                            .eq(i)
                            .text(),
                );
                surveyDebug(
                    "loc-directions-url: " +
                        jQuery(
                            ".locations > article > .store-data > .loc-directions-url",
                        )
                            .eq(i)
                            .text(),
                );
                surveyDebug(
                    "loc-postal: " +
                        jQuery(
                            ".locations > article > .store-data > .loc-postal",
                        )
                            .eq(i)
                            .text(),
                );
                surveyDebug(
                    "loc-lat: " +
                        jQuery(".locations > article > .store-data > .loc-lat")
                            .eq(i)
                            .text(),
                );
                surveyDebug(
                    "loc-lng: " +
                        jQuery(".locations > article > .store-data > .loc-lng")
                            .eq(i)
                            .text(),
                );

                locations.push({
                    address1: jQuery(
                        ".locations article > .store-data > .loc-addr",
                    )
                        .eq(i)
                        .text(),
                    address2: jQuery(
                        ".locations > article > .store-data > .loc-addr2",
                    )
                        .eq(i)
                        .text(),
                    city: jQuery(
                        ".locations > article > .store-data > .loc-city",
                    )
                        .eq(i)
                        .text(),
                    distance: jQuery(
                        ".locations > article > .store-data > .loc-distance",
                    )
                        .eq(i)
                        .text(),
                    name: jQuery(
                        ".locations > article > .store-data > .loc-name",
                    )
                        .eq(i)
                        .text(),
                    phone: jQuery(
                        ".locations > article > .store-data > .loc-phone",
                    )
                        .eq(i)
                        .text(),
                    state: jQuery(
                        ".locations > article > .store-data > .loc-state",
                    )
                        .eq(i)
                        .text(),
                    url: jQuery(
                        ".locations > article > .store-data .loc-directions-url",
                    )
                        .eq(i)
                        .text(),
                    zip: jQuery(
                        ".locations > article > .store-data > .loc-postal",
                    )
                        .eq(i)
                        .text(),
                    lat: jQuery(".locations > article > .store-data > .loc-lat")
                        .eq(i)
                        .text(),
                    lng: jQuery(".locations > article > .store-data > .loc-lng")
                        .eq(i)
                        .text(),
                });
            }

            surveyDebug("locations[], after initialized: VVVV: ");
            surveyDebug(locations);

            const emailData = {
                email: emailField.val(),
                stores: locations,
            };

            // use superagent ajax to send email data
            const submitLocations = async () => {
                try {
                    await ajax
                        .post(surveyCell1URL)
                        .set("Accept", "application/json")
                        .set(CSRF_HEADER, await getCSRFToken())
                        .send(emailData);
                } catch (e) {
                    console.error(e);
                }
            };
            submitLocations();

            // determine survey mode and move to appropriate cell
            if (getCookie(cookieEmailTaken) === "y") {
                surveyDebug("rating already taken, moving to cell3..");
                // email only survey, move to cell3
                flkty?.select(2, false, true);
                const modalInner = getModalInner();
                if (modalInner) {
                    trapFocus(modalInner);
                }

                // close survey if there is no action within 3s
                setTimeout(() => {
                    surveyDebug(
                        surveyCompletedAutoClose.toString() +
                            " seconds have elapsed, auto-closing survey..",
                    );
                    closeSurvey();
                }, surveyCompletedAutoClose * 1000);
            } else {
                // this is a full email+rating survey, so move to cell2
                surveyDebug("rating result needed, moving to cell2..");
                flkty?.next();
                const modalInner = getModalInner();
                if (modalInner) {
                    trapFocus(modalInner);
                }
            }

            return false;
        });

        // 2nd cell, "Continue" button click handler
        jQuery("input#survey-cell2-submit").on("click", () => {
            // construct Promosis rating (cell2) form in occordance to specs
            const cell2_fields_cont = jQuery(
                "form#cell2-form > .form__field--hidden",
            );
            cell2_fields_cont.append(
                '<input type="hidden" name="auth" value="' +
                    surveyPromosisAuth +
                    '">',
            );
            cell2_fields_cont.append(
                '<input type="hidden" name="brand" value="' +
                    surveyPromosisBrand +
                    '">',
            );
            cell2_fields_cont.append(
                '<input type="hidden" name="email" value="' +
                    surveyUserEmail +
                    '">',
            );

            if (
                jQuery("#survey-cell2-rating-select").val() !== "none" &&
                jQuery("#survey-cell2-rating-select").val() !== ""
            ) {
                // use jQuery.ajax() to send survey rating (cell2) form to Promosis
                jQuery.ajax({
                    type: "POST",
                    url: surveyCell2URL,
                    data: jQuery("form#cell2-form").serialize(),
                    dataType: "json",
                    success: (data) => {
                        surveyDebug(
                            "#cell2form form submitted successfully. response: VVVV",
                        );
                        surveyDebug(data);
                    },
                    error: (data) => {
                        surveyDebug("#cell2form submission FAILED. VVVV");
                        surveyDebug(data);
                    },
                });
            }
            // remove all the synthesized inputs
            cell2_fields_cont.empty();

            // record that the rating has now been taken
            surveyDebug("rating completed, saving.");
            setCookie(cookieRatingTaken, "y", cookieLifetime);

            // move to cell3
            flkty?.next();
            const modalInner = getModalInner();
            if (modalInner) {
                trapFocus(modalInner);
            }

            // close survey if there is no action within 3s
            setTimeout(() => {
                surveyDebug(
                    surveyCompletedAutoClose.toString() +
                        " seconds have elapsed, auto-closing survey..",
                );
                // run survey
                closeSurvey();
            }, surveyCompletedAutoClose * 1000);

            return false;
        });

        // click handler: X-shaped close button, top right right of survey carousel
        jQuery(selectorCloseButton).on("click", () => {
            closeDismiss();
            return false;
        });

        // click handler: all .survey-taken controls, invoked when survey is completed
        jQuery(".survey-taken").on("click", () => {
            closeSurvey();
            return false;
        });
    };

    return displaySurvey;
};

const initSurvey = async () => {
    // see if the storeLocator has found any results
    if (countResults() >= 3) {
        const displaySurvey = await loadSurvey();
        displaySurvey();
        return;
    }
    // Spin until results are loaded
    let resultsWait = 0;
    const checkResults = async () => {
        resultsWait++;
        if (resultsWait < surveyLocatorResultsWait) {
            surveyDebug(
                "hasLocatorResults: checkResults: waited " +
                    resultsWait.toString() +
                    " seconds for retail locator results to display",
            );
            // see if the storeLocator has found at least 3 results on page
            if (countResults() >= 3) {
                const displaySurvey = await loadSurvey();
                displaySurvey();
                return;
            }
            setTimeout(() => {
                checkResults();
            }, 1 * 1000);
            return;
        } else {
            surveyDebug(
                "hasLocatorResults: checkResults: waited " +
                    resultsWait.toString() +
                    " seconds for results. Giving up.",
            );
            return;
        }
    };
    checkResults();
};

// global function, use to invoke survey
const startSurvey = async (survey_mode?: string, queriedLocation?: string) => {
    // if survey is disabled, bail out
    if (!surveyEnabled) {
        surveyDebug("survey disabled, skipping.");
        return;
    }

    // > abort if query hasn't changed since the last run
    if (window.lastLocationSearched === queriedLocation) {
        return;
    }

    // > update lastLocationSearched with the new location query
    if (queriedLocation) {
        window.lastLocationSearched = queriedLocation.toString();
    }

    // check to see if survey has already been taken
    if (survey_mode) {
        if (getCookie(cookieEmailTaken) !== "") {
            // survey already taken, skip
            surveyDebug(
                "start_survey: completed within the last " +
                    cookieLifetime.toString() +
                    " days, skipping survey.",
            );
            return;
        }
    }
    // If there's retail store results, show the survey
    await initSurvey();
};

// pre-pop mode
surveyDebug("pausing " + String(surveyPrepopModePause / 1000) + " seconds");
setTimeout(() => {
    surveyDebug("pre-pop mode: resuming after pause..");
    startSurvey();
}, surveyPrepopModePause);

// submission using the zip input field submit handler
export const triggerSurvey = (searchText?: string) => {
    surveyDebug("email mode: zip form submitted");
    surveyDebug("pausing " + String(surveyEmailModePause / 1000) + " seconds");
    setTimeout(() => {
        surveyDebug("resuming after pause..");
        // run survey in email-only mode
        startSurvey("email", searchText);
    }, surveyEmailModePause);
};
