import React, { useMemo } from "react";
import { interpolate } from "@thelabnyc/thelabui/src/utils/i18n";
import { FinancingModalTrigger } from "tsi-common-react/src/apps/financing/FinancingModalTrigger";
import { urls } from "tsi-common-react/src/utils/urls";
import { Link } from "tsi-common-react/src/common/Link";
import { formatFinancingPlan } from "tsi-common-react/src/utils/format";
import { getDefaultFinancingPlan } from "tsi-common-react/src/utils/financing";
import stearnsCcLarge from "../../img/finance/stearns-cc_large.png";

interface IProps {
    applicationSource?: string;
}

export const FinancingBannerBlock = (props: IProps) => {
    const plan = useMemo(
        () => formatFinancingPlan(getDefaultFinancingPlan()),
        [],
    );
    return (
        <div className="financing-block">
            <div className="financing-block__block-container">
                <img
                    className="financing-block__icon financing-block__icon--img"
                    alt={gettext("Stearns and Foster Credit Card")}
                    src={stearnsCcLarge}
                />
                <div className="financing-block__terms">
                    <h5>
                        {interpolate(
                            gettext("%(apr)s APR for %(length)s Months"),
                            plan,
                        )}
                        <sup>
                            <Link href={urls.pageURL("finance-link")}>
                                {plan.superscript}
                                <span className="ada-screenreader-only">
                                    {gettext(
                                        "Additional information about Terms and Conditions",
                                    )}
                                </span>
                            </Link>
                        </sup>
                    </h5>
                    <ul className="financing-block__terms-line financing-block__terms-line--1">
                        <li>
                            {gettext("No Annual Fee")}
                            <sup>
                                <Link href={urls.pageURL("finance-link")}>
                                    {plan.superscript}
                                    <span className="ada-screenreader-only">
                                        {gettext(
                                            "Additional information about Terms and Conditions",
                                        )}
                                    </span>
                                </Link>
                            </sup>
                        </li>
                        <li>
                            {gettext(
                                "Pre-qualify without impacting your credit score",
                            )}
                        </li>

                        <li>{gettext("Apply now or during checkout")}</li>
                    </ul>
                </div>

                <FinancingModalTrigger
                    modalType="prequal-app"
                    className="button button--secondary"
                    applicationSource={props.applicationSource}
                >
                    {gettext("Check If You Pre-Qualify")}
                </FinancingModalTrigger>
                <FinancingModalTrigger
                    modalType="full-app"
                    className="button"
                    applicationSource={props.applicationSource}
                >
                    {gettext("Apply Now")}
                </FinancingModalTrigger>
            </div>
        </div>
    );
};
