import React from "react";
import { connect } from "react-redux";
import { IProduct } from "tsi-common-react/src/models/catalogue.interfaces";
import { upgradedVariantSelector } from "tsi-common-react/src/apps/configurator/selectors";
import { IReduxState } from "../../reducers.interfaces";

interface IOwnProps {}

interface IReduxProps {
    upgradedVariant: IProduct | null;
}

interface IDispatchProps {}

interface IProps extends IOwnProps, IReduxProps, IDispatchProps {}

interface IState {}

class PDPAnalyticsComponent extends React.Component<IProps, IState> {
    componentDidMount() {
        this.updateDataLayerPrice();
    }

    componentDidUpdate() {
        this.updateDataLayerPrice();
    }

    private updateDataLayerPrice() {
        // update the dataLayer with the variant price
        window.dataLayer.push({
            pageCategory: "PDP Page",
            ProductValue: this.props.upgradedVariant
                ? this.props.upgradedVariant.price.cosmetic_incl_tax
                : "",
            event: "PDPValue",
        });
    }

    render() {
        return null;
    }
}

const mapStateToProps = (
    state: IReduxState,
    ownProps: IOwnProps,
): IOwnProps & IReduxProps => {
    return {
        ...ownProps,
        upgradedVariant: upgradedVariantSelector(state.configurator),
    };
};

export const PDPAnalytics = connect(mapStateToProps)(PDPAnalyticsComponent);
