import React from "react";
import classNames from "classnames";
import SVG from "react-inlinesvg";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import { IProductAPIURL } from "tsi-common-react/src/models/nominals";
import {
    IProduct,
    IBasket,
    IBasketLine,
} from "tsi-common-react/src/models/catalogue.interfaces";
import { TGetProductUpsell } from "tsi-common-react/src/apps/checkout/components/BasketLineFull";
import { BundleGroupTypes } from "tsi-common-react/src/constants";
import { getDinero } from "tsi-common-react/src/utils/money";
import { BasketLineSuggestion } from "./BasketLineSuggestion";
import iconCircle from "../../svg/circle.svg";

interface IProps {
    basket: IBasket;
    line: IBasketLine;
    addBasketLine: (url: IProductAPIURL, qty: number) => void;
}

interface IState {
    suggestionIndex: number;
}

export class BasketLineSuggestions extends React.Component<IProps, IState> {
    public state: IState = {
        suggestionIndex: 0,
    };

    private prevSuggestion() {
        const i = Math.max(0, this.state.suggestionIndex - 1);
        this.setState({ suggestionIndex: i });
    }

    private nextSuggestion() {
        const products = this.listSuggestedProducts();
        const i = Math.min(products.length - 1, this.state.suggestionIndex + 1);
        this.setState({ suggestionIndex: i });
    }

    private listSuggestedProducts() {
        const line = this.props.line;
        if (!line.bundles) {
            return [];
        }

        // Create a mapping of products IDs in the basket to the price of the item
        const pids = this.props.basket.lines.map((l) => {
            return l.product.url;
        });

        // Flatten suggested products from bundles
        let products = line.bundles
            .filter((bundle) => {
                const bundle_type = bundle.bundle_group.bundle_type;
                return (
                    bundle_type === BundleGroupTypes.DEFAULT ||
                    bundle_type === BundleGroupTypes.IN_BASKET_ADD_ON
                );
            })
            .map((bundle) => {
                return bundle.suggested_products;
            })
            .reduce((memo, suggestions) => {
                return memo.concat(suggestions);
            }, []);

        // Figure out the minimum cost to include as a suggestion.
        const minSuggestionPrice = products.reduce((memo, product) => {
            const price = getDinero(product.price.cosmetic_excl_tax || "0.00");
            if (pids.indexOf(product.url) !== -1 && price.greaterThan(memo)) {
                return price;
            }
            return memo;
        }, getDinero(0));

        // Filter out products already in the basket
        products = products.filter((product) => {
            const isAlreadyInCart = pids.indexOf(product.url) !== -1;
            const isAboveSugestedPrice = getDinero(
                product.price.cosmetic_excl_tax || "0.00",
            ).greaterThan(minSuggestionPrice);
            if (!isAlreadyInCart && isAboveSugestedPrice) {
                pids.push(product.url);
                return true;
            }
            return false;
        });

        // Sort from most expensive to least expensive
        products = products.sort((productA, productB) => {
            const priceA = getDinero(
                productA.price.cosmetic_excl_tax || "0.00",
            );
            const priceB = getDinero(
                productB.price.cosmetic_excl_tax || "0.00",
            );
            return priceB.subtract(priceA).toUnit();
        });

        return products;
    }

    // Should also be removed, builds Basket SuggestionComponent based off suggestion #
    private getSuggestion(product: IProduct) {
        return (
            <BasketLineSuggestion
                line={this.props.line}
                product={product}
                basketTotal={getDinero(this.props.basket.total_incl_tax)}
                addBasketLine={this.props.addBasketLine}
            />
        );
    }

    render() {
        const products = this.listSuggestedProducts();
        const suggestionIndex = this.state.suggestionIndex;
        const product = products[suggestionIndex];

        if (!product) {
            return null;
        }

        const circles = products.map((_, index) => {
            const circleClasses = classNames({
                "basket-suggestion__details__suggestion-controls__circles__item":
                    true,
                "basket-suggestion__details__suggestion-controls__circles__item--filled":
                    suggestionIndex !== index,
            });

            return (
                <SVG key={index} className={circleClasses} src={iconCircle} />
            );
        });

        const hasPrev = suggestionIndex > 0;
        const hasNext = suggestionIndex < products.length - 1;

        const suggestionControls = products.length > 1 && (
            <div className="basket-suggestion__details__suggestion-controls">
                <button
                    onClick={() => {
                        this.prevSuggestion();
                    }}
                    disabled={!hasPrev}
                >
                    &lt;
                </button>
                <span className="basket-suggestion__details__suggestion-controls__circles">
                    {circles}
                </span>
                <button
                    onClick={() => {
                        this.nextSuggestion();
                    }}
                    disabled={!hasNext}
                >
                    &gt;
                </button>
            </div>
        );

        const basketSuggestion = this.getSuggestion(product);

        return (
            <>
                <TransitionGroup>
                    <CSSTransition
                        key={`${product.url}`}
                        classNames="transition--fade"
                        timeout={{ exit: 500, enter: 500 }}
                    >
                        {basketSuggestion}
                    </CSSTransition>
                </TransitionGroup>
                {suggestionControls}
            </>
        );
    }
}

export const getLineProductUpsell: TGetProductUpsell = (
    basket,
    line,
    addBasketLine,
) => {
    const content = (
        <BasketLineSuggestions
            basket={basket}
            line={line}
            addBasketLine={addBasketLine}
        />
    );
    return {
        placement: "in-details",
        hideBottomBorder: false,
        content: content,
    };
};
