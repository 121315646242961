import { onDOMContentLoaded } from "tsi-common-react/src/utils/events";
import { initPromoCalloutMessages } from "tsi-common-react/src/utils/domManipulation";

// Category menu nav for Tempur loave story page
const setCategoryMenuActive = () => {
    Array.from(
        document.querySelectorAll<HTMLElement>(".menu__nav-item"),
    ).forEach((elem) => {
        const menu = document.querySelector<HTMLElement>(
            ".breadcrumb__list-item--category-slug",
        );
        if (menu) {
            if (menu.dataset.slug === elem.dataset.slug) {
                elem.classList.add("menu__nav-item--active");
            }
        }
    });
};

// Dynamic Upsell Info Modal Panel Offset CSS Variable
const setUpsellInfoModalPanelOffset = (element: HTMLElement) => {
    const offsetValue = element.getBoundingClientRect().top;
    let offset = `${offsetValue}px`;
    if (offsetValue < 0) {
        offset = "0px";
    }
    document.documentElement.style.setProperty(
        "--configurator--upsell--panel-offset",
        offset,
    );
};

const checkUpsellInfoModalPanelOffset = () => {
    const content = document.getElementById("main-content");
    if (content) {
        setUpsellInfoModalPanelOffset(content);
    }
    window.addEventListener("scroll", () => {
        if (content) {
            setUpsellInfoModalPanelOffset(content);
        }
    });
};

// Dynamic Upsell Info Modal Overlay Footer Offset CSS Variable
const setUpsellInfoModalPanelFooterOffset = () => {
    const content = document.querySelector(".service-footer-container");
    if (content) {
        // this is the hardcoded height of the Service Footer
        const offset = "50px";
        document.documentElement.style.setProperty(
            "--configurator--upsell--panel-overlay-footer-offset",
            offset,
        );
    }
};

// Product Hero Section Sticky Offset
const setProductHeroSectionOffset = () => {
    const content = document.getElementById("main-content");
    if (content) {
        const offset = `${content.getBoundingClientRect().top}px`;
        document.documentElement.style.setProperty(
            "--product-hero--sticky-offset",
            offset,
        );
    }
};

// Disable the accordion functionality of
// Rich Navigation Link Collections
const rich_navigations = document.querySelectorAll<HTMLElement>(
    "nav.rich-navigation",
);
if (rich_navigations) {
    Array.from(rich_navigations).forEach((rich_navigation) => {
        Array.from(
            rich_navigation.querySelectorAll(
                ".rich-navigation--link-collection",
            ),
        ).forEach((linkCollection) => {
            linkCollection
                .querySelector<HTMLElement>("button")
                ?.removeAttribute("aria-expanded");
            linkCollection
                .querySelector<HTMLElement>(".collection")
                ?.removeAttribute("aria-hidden");
        });
    });
}

// Mobile rich nav sticky on scroll
const registerEventListeners_StickyNavToggle = () => {
    const isProductComparePage =
        document.querySelector<HTMLElement>("body")?.dataset.trackPageType ===
        "ProductComparePage";

    if (rich_navigations && !isProductComparePage) {
        const headerElem = document.querySelector<HTMLElement>("header");
        let lastScrollTop = 0;

        window.addEventListener("scroll", () => {
            const st = window.pageYOffset;

            if (headerElem) {
                if (
                    st > headerElem.getBoundingClientRect().height &&
                    st > lastScrollTop
                ) {
                    headerElem?.classList.add("sticky");
                } else {
                    headerElem?.classList.remove("sticky");
                }
                lastScrollTop = st <= 0 ? 0 : st;
            }
        });
    }
};

// Register functions after body loads
onDOMContentLoaded.on(() => {
    setCategoryMenuActive();
    checkUpsellInfoModalPanelOffset();
    setUpsellInfoModalPanelFooterOffset();
    setProductHeroSectionOffset();
    registerEventListeners_StickyNavToggle();
    initPromoCalloutMessages();
});
