import * as wishlistsAPI from "tsi-common-react/src/api/wishlists";
import { Loaders } from "./loaders";

export class Actions {
    private readonly loaders: Loaders;

    constructor(loaders: Loaders) {
        this.loaders = loaders;
    }

    removeWishlistLine(lineURL: string) {
        return wishlistsAPI.removeLine(lineURL).then(() => {
            return this.loaders.loadFavorites();
        });
    }
}
