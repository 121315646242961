import { onDOMContentLoaded } from "tsi-common-react/src/utils/events";

const registerEventListeners_Accordion = () => {
    setAccordionListener();
};

// Accordion logic exported to enable usage for ajax loaded accordions (like the offers page modal)
export const setAccordionListener = () => {
    // Accordion
    Array.from(document.querySelectorAll<HTMLElement>(".accordion")).forEach(
        (elem) => {
            if (elem.classList.contains("accordion--loaded")) {
                return;
            }
            elem.classList.add("accordion--loaded");
            elem.addEventListener("click", function (event) {
                event.preventDefault();
                const ariaExpanded = this.getAttribute("aria-expanded");
                if (ariaExpanded === "true" || ariaExpanded === "false") {
                    this.setAttribute(
                        "aria-expanded",
                        ariaExpanded === "true" ? "false" : "true",
                    );
                }

                this.classList.toggle("accordion--is-active");
                const parentElement = this.closest("li") || this.parentNode;
                if (!parentElement) {
                    return;
                }

                Array.from(parentElement.children)
                    .filter((e) => {
                        return e.classList.contains("accordion-target");
                    })
                    .forEach((e) => {
                        e.classList.toggle("accordion-target--is-active");
                    });
            });
        },
    );
};
const updateFooterClass = () => {
    Array.from(
        document.querySelectorAll<HTMLElement>("footer .accordion-target"),
    ).forEach((elem) => {
        elem.classList.add("accordion-target--is-active");
    });
};

const registerEventListeners_OrderPage = () => {
    if (!document.body.classList.contains("orders-page")) {
        return;
    }

    Array.from(
        document.querySelectorAll<HTMLElement>(
            ".order-summary-details__info-column--order-details",
        ),
    ).forEach((elem) => {
        elem.addEventListener("click", function () {
            if (!this.parentNode) {
                return;
            }

            const orderSummary = this.parentNode.parentNode as HTMLElement;
            Array.from(orderSummary.children)
                .filter((e) => {
                    return e.classList.contains("accordion-target");
                })
                .forEach((e) => {
                    e.classList.toggle("accordion-target--is-active");
                });

            this.classList.toggle(
                "order-summary-details__info-column--order-details--close",
            );
        });
    });
};

// Register functions after body loads
onDOMContentLoaded.on(() => {
    updateFooterClass();
    registerEventListeners_Accordion();
    registerEventListeners_OrderPage();
});
