import React from "react";
import { connect } from "react-redux";
import { TDispatchMapper } from "tsi-common-react/src/apps/reducers.interfaces";
import {
    IRetailStoreWithDistance,
    ILocation,
} from "tsi-common-react/src/models/location.interfaces";
import { ISyncStoreID } from "tsi-common-react/src/models/nominals";
import { preferredLocationSelector } from "tsi-common-react/src/apps/common/selectors";
import { formatPhoneNumber } from "tsi-common-react/src/utils/format";
import {
    filteredStoreSelector,
    focusedStoreIDSelector,
} from "tsi-common-react/src/apps/retail/selectors";
import { Dispatchers } from "tsi-common-react/src/apps/retail/dispatchers";
import { IReduxState } from "../../reducers.interfaces";
import { RetailLocatorBlock } from "../components/RetailLocator";

interface IOwnProps {}

interface IReduxProps {
    stores: IRetailStoreWithDistance[];
    focusedStoreID: ISyncStoreID | null;
    location: ILocation | null;
}

interface IDispatchProps {
    dispatchers: Dispatchers;
}

interface IProps extends IOwnProps, IReduxProps, IDispatchProps {}

interface IState {}

class RetailLocatorContainer extends React.Component<IProps, IState> {
    private readonly onBeforeInfoWindowOpen = (
        info: google.maps.InfoWindow,
        store: IRetailStoreWithDistance,
    ) => {
        this.props.dispatchers.setFocusedStore(store.external_id);
        const content = `
            <h5>${store.name}</h5>
            <p>${store.address}</p>
            <p>${store.city}, ${store.state} ${store.postal}</p>
            <p>${formatPhoneNumber(store.phone)}</p>
        `;
        info.setContent(content);
    };

    private readonly onInfoWindowClose = () => {
        this.props.dispatchers.setFocusedStore(null);
        const locationsList = document.querySelector("div.locations");
        if (locationsList) {
            locationsList.scrollTop = 0;
        }
    };

    render() {
        return (
            <RetailLocatorBlock
                location={this.props.location}
                stores={this.props.stores}
                focusedStoreID={this.props.focusedStoreID}
                onBeforeInfoWindowOpen={this.onBeforeInfoWindowOpen}
                onInfoWindowClose={this.onInfoWindowClose}
            />
        );
    }
}

const mapStateToProps = (rootState: IReduxState): IReduxProps => {
    return {
        location: preferredLocationSelector(rootState),
        stores: filteredStoreSelector(rootState),
        focusedStoreID: focusedStoreIDSelector(rootState),
    };
};

const mapDispatchToProps: TDispatchMapper<IDispatchProps> = (dispatch) => {
    const dispatchers = new Dispatchers(dispatch);
    return {
        dispatchers: dispatchers,
    };
};

export const RetailLocator = connect(
    mapStateToProps,
    mapDispatchToProps,
)(RetailLocatorContainer);
