import { onDOMContentLoaded } from "tsi-common-react/src/utils/events";

const buildCarousel = () => {
    Array.from(document.querySelectorAll<HTMLElement>(".carousel")).forEach(
        (elem) => {
            // grab images
            const images = JSON.parse(elem.dataset.cms || "");
            // grab pips
            const pips = elem.children.item(2);
            // default pip
            let rawPip = "";

            // if we have pips...
            if (pips) {
                // grab the first pip in case it's different than the default
                const pip = pips.children.item(0);
                // update the rawPip
                if (pip) {
                    rawPip = String(pip.outerHTML);
                }

                // add as many pips as we have images
                for (let i = 1; i < images.length; i++) {
                    pips.innerHTML += rawPip;
                }

                // add the class of 'current' to the first pip
                if (pips.firstElementChild) {
                    pips.firstElementChild.classList.add("current");
                }
            }

            // find and add event listeners to nav buttons
            const nav = elem.children.item(0);
            if (nav) {
                const prevNav = nav.children.item(0);
                const nextNav = nav.children.item(1);
                console.log(prevNav);
                console.log(nextNav);
            }
        },
    );
};

onDOMContentLoaded.on(() => {
    buildCarousel();
});
