import React from "react";
import { connect } from "react-redux";
import SVG from "react-inlinesvg";
import {
    TStateMapper,
    TDispatchMapper,
} from "tsi-common-react/src/apps/reducers.interfaces";
import { IWishListLine } from "tsi-common-react/src/models/catalogue.interfaces";
import { Dispatchers } from "tsi-common-react/src/apps/checkout/dispatchers";
import {
    LIST_FAVORITES,
    SAVED_FAVORITES,
} from "tsi-common-react/src/apps/configurator/constants";
import { Loaders } from "./loaders";
import iconHeart from "../svg/heart.svg";

interface IWishlists {
    [code: string]: IWishListLine[];
}

interface IOwnProps {}

interface IReduxProps {
    wishlists: IWishlists;
    addToFavoritesButtonText: string;
}

interface IDispatchProps {
    loaders: Loaders;
    dispatchers: Dispatchers;
}

interface IProps extends IOwnProps, IReduxProps, IDispatchProps {}

interface IState {}

class FavoritesIconComponent extends React.Component<IProps, IState> {
    componentDidUpdate() {
        if (this.props.addToFavoritesButtonText === SAVED_FAVORITES) {
            this.props.loaders.loadFavorites();
        }
    }

    render() {
        const lines = this.props.wishlists[LIST_FAVORITES];
        return (
            <>
                <SVG
                    aria-hidden={true}
                    className="header-svg"
                    src={iconHeart}
                    title="Heart Icon"
                />
                {lines ? lines.length : "0"}
            </>
        );
    }
}

const mapStateToProps: TStateMapper<
    "checkout" | "configurator",
    IReduxProps,
    IOwnProps
> = (rootState, ownProps) => {
    return {
        wishlists: rootState.checkout.data.wishlists,
        addToFavoritesButtonText:
            rootState.configurator.ui.addToFavoritesButtonText,
        ...ownProps,
    };
};

const mapDispatchToProps: TDispatchMapper<IDispatchProps> = (dispatch) => {
    const dispatchers = new Dispatchers(dispatch);
    const loaders = new Loaders(dispatchers);
    return {
        dispatchers: dispatchers,
        loaders: loaders,
    };
};

export const FavoritesIcon = connect(
    mapStateToProps,
    mapDispatchToProps,
)(FavoritesIconComponent);
