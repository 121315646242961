import { getReviewAPIURL } from "tsi-common-react/src/api/reviews";
import { ajax } from "tsi-common-react/src/utils/ajax";
import { onDOMContentLoaded } from "tsi-common-react/src/utils/events";
import { placeLinkNumber } from "./index";

const buildReviewsLinkNumber = () => {
    const apiURL = getReviewAPIURL("/reviews");
    const query = {
        brand_id: "2",
    };
    return ajax
        .get(apiURL)
        .query(query)
        .then((resp) => {
            return resp.body.count;
        })
        .then((num) => {
            placeLinkNumber(num, "reviews-link");
        });
};

onDOMContentLoaded.on(() => {
    buildReviewsLinkNumber();
});
