import React from "react";
import { connect } from "react-redux";
import classNames from "classnames";
import { TDispatchMapper } from "tsi-common-react/src/apps/reducers.interfaces";
import { Dispatchers } from "tsi-common-react/src/apps/configurator/dispatchers";
import { rootProductSelector } from "tsi-common-react/src/apps/configurator/selectors";
import { ISafeHTML } from "tsi-common-react/src/models/nominals";
import {
    IOptionCode,
    IOptionLabel,
    IOptionValue,
    IOptionSingleValue,
} from "tsi-common-react/src/models/catalogue.interfaces";
import { notEmpty, unique } from "tsi-common-react/src/utils/functional";
import { sortProductOptions } from "tsi-common-react/src/utils/sorting";
import { RawHTML } from "tsi-common-react/src/common/RawHTML";
import { IReduxState } from "../../reducers.interfaces";

interface IOwnProps {
    attributeCode: IOptionCode;
}

interface IReduxProps {
    namespace: string | null;
    selectedValue: IOptionValue | null;
    optionValues: IOptionSingleValue[];
    optionGroups: IOptionLabel[] | null;
    productTitle: string | null;
    productDescription: ISafeHTML | null;
}

interface IDispatchProps {
    dispatchers: Dispatchers;
}

interface IProps extends IOwnProps, IReduxProps, IDispatchProps {}

interface IState {}

class PDPChangeVariantOptionBlockComponent extends React.Component<
    IProps,
    IState
> {
    private onOptionSelectorClick(
        event: React.MouseEvent<HTMLElement>,
        value: string,
    ) {
        event.preventDefault();
        if (!this.props.namespace) {
            return;
        }
        this.props.dispatchers.setOptionValue(
            this.props.namespace,
            this.props.attributeCode,
            0,
            1,
            value,
        );
    }

    render() {
        // Figure out how to group the radio options (based on the product_option_labels attribute)
        const labelConfig = this.props.optionGroups || [];
        if (labelConfig.length <= 0) {
            labelConfig.push({
                label: "",
                values: this.props.optionValues,
            });
        }

        // Render each label group
        const groups = labelConfig.map((labelGroup) => {
            const optionBtns = labelGroup.values.map((option) => {
                // Make sure option is valid
                if (this.props.optionValues.indexOf(option) === -1) {
                    return null;
                }
                const optionClass = classNames({
                    "--active": option === this.props.selectedValue,
                });
                return (
                    <button
                        key={option}
                        data-value={option}
                        onClick={(e) => {
                            this.onOptionSelectorClick(e, option);
                        }}
                        className={optionClass}
                    >
                        {option}
                    </button>
                );
            });
            return (
                <nav key={labelGroup.label}>
                    <h5>{labelGroup.label}:</h5>
                    {optionBtns}
                </nav>
            );
        });
        return (
            <React.Fragment key={this.props.attributeCode}>
                {this.props.productTitle && <h1>{this.props.productTitle}</h1>}
                {this.props.productDescription && (
                    <RawHTML html={this.props.productDescription} />
                )}
                <div>{groups}</div>
            </React.Fragment>
        );
    }
}

const getOptionValues = (
    state: Pick<IReduxState, "configurator">,
    attributeCode: IOptionCode,
): IOptionSingleValue[] => {
    const rootProduct = rootProductSelector(state.configurator);
    if (!rootProduct) {
        return [];
    }
    const children = rootProduct.children;
    if (!children || children.length <= 0) {
        const attr = rootProduct.attributes[attributeCode];
        if (!attr) {
            return [];
        }
        return Array.isArray(attr.value) ? attr.value : [attr.value];
    }
    const values = children
        .reduce<Array<IOptionSingleValue | null>>((memo, child) => {
            const attr = child.attributes[attributeCode];
            return memo.concat(attr ? attr.value : null);
        }, [])
        .filter(notEmpty)
        .filter(unique);
    return sortProductOptions(attributeCode, values);
};

const getOptionGroups = (state: IReduxState, attributeCode: IOptionCode) => {
    const rootProduct = rootProductSelector(state.configurator);
    if (rootProduct) {
        return (
            rootProduct.attributes.product_option_labels?.value[
                attributeCode
            ] || null
        );
    }
    return null;
};

const mapStateToProps = (
    state: IReduxState,
    ownProps: IOwnProps,
): IOwnProps & IReduxProps => {
    const values = getOptionValues(state, ownProps.attributeCode);
    const optionGroups = getOptionGroups(state, ownProps.attributeCode);
    const rootProduct = rootProductSelector(state.configurator);
    return {
        ...ownProps,
        namespace: rootProduct ? rootProduct.product_class_slug : null,
        selectedValue:
            state.configurator.ui.optionValues[ownProps.attributeCode] || null,
        optionValues: values,
        optionGroups: optionGroups,
        productTitle: rootProduct ? rootProduct.title : null,
        productDescription: rootProduct ? rootProduct.description : null,
    };
};

const mapDispatchToProps: TDispatchMapper<IDispatchProps> = (dispatch) => {
    const dispatchers = new Dispatchers(dispatch);
    return {
        dispatchers: dispatchers,
    };
};

export const PDPChangeVariantOptionBlock = connect(
    mapStateToProps,
    mapDispatchToProps,
)(PDPChangeVariantOptionBlockComponent);
