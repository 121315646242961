import React from "react";
import classNames from "classnames";
import {
    ILocation,
    IRetailStoreWithDistance,
} from "tsi-common-react/src/models/location.interfaces";
import {
    ISyncStoreID,
    isoLatitude,
    isoLongitude,
} from "tsi-common-react/src/models/nominals";
import { LocationInput } from "tsi-common-react/src/apps/common/containers/LocationInput";
import { FilterDistanceSelect } from "tsi-common-react/src/apps/retail/containers/FilterDistanceSelect";
import { MarkerMap } from "tsi-common-react/src/apps/retail/containers/MarkerMap";
import { Link } from "tsi-common-react/src/common/Link";
import { formatDistanceWithSuffix } from "tsi-common-react/src/apps/retail/helpers";
import { formatPhoneNumber } from "tsi-common-react/src/utils/format";
import { triggerSurvey } from "../../helpers/survey";
import openLinkIconBlue from "../../img/retail-locator/open-link-icon-blue.png";
import iconSearch from "../../img/icons/search.svg";

interface ILocationProps {
    store: IRetailStoreWithDistance;
    isFocused: boolean;
}

interface IContainerProps {
    stores: IRetailStoreWithDistance[];
    focusedStoreID: ISyncStoreID | null;
    location: ILocation | null;
    onBeforeInfoWindowOpen: (
        info: google.maps.InfoWindow,
        store: IRetailStoreWithDistance,
    ) => void;
    onInfoWindowClose: () => void;
}

const RetailLocation = (props: ILocationProps) => {
    // encode destination address for Directions link
    const destinationAddress = encodeURI(
        props.store.address +
            " " +
            props.store.address2 +
            " " +
            props.store.city +
            "," +
            props.store.state +
            " " +
            props.store.postal,
    );
    const articleClasses = classNames({
        "--is-current": props.isFocused,
    });
    const storeDataStyle = {
        display: "none",
    };
    let websiteLinkElement: JSX.Element | null = null;
    if (props.store.web_stearns) {
        websiteLinkElement = (
            <Link
                className="loc-website"
                href={props.store.web_stearns}
                target="_blank"
                rel="nofollow noreferrer"
            >
                Website
                <img src={openLinkIconBlue} alt="Website" />
            </Link>
        );
    }
    return (
        <article className={articleClasses} role="group" aria-label="location">
            <h3>{props.store.name}</h3>
            <p>{props.store.address}</p>
            {props.store.address2 && <p>{props.store.address2}</p>}
            <p>
                {props.store.city}, {props.store.state} {props.store.postal}
            </p>
            <a href={`tel:${props.store.phone}`}>
                {formatPhoneNumber(props.store.phone)}
            </a>
            <p>{formatDistanceWithSuffix(props.store.distance, 2)}</p>
            <a
                className="loc-directions"
                href={`https://www.google.com/maps/dir/?api=1&destination=${destinationAddress}`}
                target="_blank"
                rel="noreferrer"
            >
                Directions
            </a>
            {websiteLinkElement}
            <div className="store-data ada-hidden" style={storeDataStyle}>
                <span className="loc-addr">{props.store.address}</span>
                <span className="loc-addr2">{props.store.address2}</span>
                <span className="loc-city">{props.store.city}</span>
                <span className="loc-distance">
                    {formatDistanceWithSuffix(props.store.distance, 2)}
                </span>
                <span className="loc-name">{props.store.name}</span>
                <span className="loc-phone">{props.store.phone}</span>
                <span className="loc-state">{props.store.state}</span>
                <span className="loc-postal">{props.store.postal}</span>
                <span className="loc-lat">
                    {isoLatitude.unwrap(props.store.lat)}
                </span>
                <span className="loc-lng">
                    {isoLongitude.unwrap(props.store.lng)}
                </span>
                <span className="loc-directions-url">
                    {`https://www.google.com/maps/dir/?api=1&destination=${destinationAddress}`}
                </span>
            </div>
        </article>
    );
};

export class RetailLocatorBlock extends React.Component<
    IContainerProps,
    Record<string, never>
> {
    private readonly onAfterLocationChange = (searchText: string) => {
        window.dataLayer.push({
            event: "Zip Entry Click",
            ZipCode: searchText,
        });

        triggerSurvey(this.props.location?.formatted_address);
    };

    render() {
        const locationInputID = "retail-locator-location-input";
        const distanceInputID = "retail-locator-distance-input";
        return (
            <>
                <form
                    onSubmit={(e) => {
                        e.preventDefault();
                    }}
                >
                    <label htmlFor={locationInputID}>
                        Find local Stearns &amp; Foster retailers by entering
                        your location here.
                    </label>
                    <LocationInput
                        inputFormat="short"
                        inputProps={{
                            id: locationInputID,
                            placeholder: "Enter your zip code",
                        }}
                        onAfterChange={this.onAfterLocationChange}
                    />
                    <button className="al-location--submit-zip" type="submit">
                        <img src={iconSearch} alt="Search Icon" />
                    </button>
                    <label
                        className="distance-wrapper"
                        htmlFor={distanceInputID}
                    >
                        <FilterDistanceSelect
                            selectProps={{
                                id: distanceInputID,
                                className: "retailers__form-label-arrow",
                                name: "bh-sl-maxdistance",
                                title: gettext("Retailer Distance"),
                            }}
                        />
                    </label>
                </form>
                <div>
                    <MarkerMap
                        stores={this.props.stores}
                        defaultCenter={this.props.location}
                        markerOptions={() => {
                            return {
                                icon: {
                                    strokeColor: "#9f2241",
                                    strokeWeight: 2,
                                    scale: 1.5,
                                    path: `M7.61905 20C7.61905 20 15.2381 11.8269 15.2381 7.61905C15.2381 3.41117 11.8269 0 7.61905 0C3.41117 0 0 3.41117 0 7.61905C0
                                    11.8269 7.61905 20 7.61905 20ZM7.61905 11.4286C9.72302 11.4286 11.4286 9.72299 11.4286 7.61905C11.4286 5.51511 9.72302 3.80952
                                    7.61905 3.80952C5.51508 3.80952 3.80952 5.51511 3.80952 7.61905C3.80952 9.72299 5.51508 11.4286 7.61905 11.4286Z`,
                                },
                            };
                        }}
                        elemProps={{
                            id: "retail-locator-map",
                        }}
                        onBeforeInfoWindowOpen={
                            this.props.onBeforeInfoWindowOpen
                        }
                        onInfoWindowClose={this.props.onInfoWindowClose}
                    />
                    <div className="locations">
                        {this.props.location && (
                            <h5 className="marked">
                                {this.props.stores.length} locations near{" "}
                                {this.props.location.formatted_address}
                            </h5>
                        )}
                        {this.props.stores.map((store) => (
                            <RetailLocation
                                {...this.props}
                                key={`${store.external_id}`}
                                store={store}
                                isFocused={
                                    this.props.focusedStoreID ===
                                    store.external_id
                                }
                            />
                        ))}
                    </div>
                </div>
            </>
        );
    }
}
