import { onDOMContentLoaded } from "tsi-common-react/src/utils/events";

const replaceImg = (url: string, mainImg: Element) => {
    mainImg.setAttribute("src", url);
};

const clearSelected = (nav: Element) => {
    for (let i = 0; i < nav.children.length; i++) {
        const img = nav.children[i];
        img.classList.remove("selected");
    }
};

export const buildGallery = () => {
    Array.from(document.querySelectorAll<HTMLElement>(".gallery")).forEach(
        (elem) => {
            const nav = elem.children.item(0);
            const mainImg = elem.children.item(1);
            if (nav && mainImg) {
                const firstImg = nav.getElementsByTagName("img")[0];

                if (firstImg) {
                    firstImg.classList.add("selected");
                }

                for (let i = 0; i < nav.children.length; i++) {
                    // type it so we can access the dataset
                    const img = nav.children[i] as HTMLElement;

                    img.addEventListener("click", (e) => {
                        e.preventDefault();

                        // clear the current selection
                        clearSelected(nav);
                        // assign the new selection
                        img.classList.add("selected");
                        if (img.dataset.original) {
                            replaceImg(img.dataset.original, mainImg);
                        }
                    });
                }
            }
        },
    );
};

onDOMContentLoaded.on(() => {
    buildGallery();
});
