export const placeLinkNumber = (num: number | string, cssClassName: string) => {
    num = String(num);
    const links = document.getElementsByClassName(cssClassName);
    for (let i = 0; i < links.length; i++) {
        links[i].insertAdjacentHTML("beforeend", "<em>" + num + "</em>");
    }
};

export const clearSelectedClass = (elem: HTMLElement, targetClass: string) => {
    Array.from(elem.getElementsByClassName(targetClass)).forEach((subBlock) => {
        subBlock.classList.remove(CURRENT_CLASS);
    });
};

export const CURRENT_CLASS = "--is-current";

import "./carousel";
import "tsi-common-react/src/blocks/columns-carousel-block";
import "./detail-slider";
import "./favorites-link";
import "./featured-products";
import "./gallery";
import "./menu";
import "./reviews-link";
import "./tabs";
import "tsi-common-react/src/blocks/wistia-background-video";
import "./survey";
import "./lazyloading";
import "./accordion";
import "./finance-faq";
import "./helpers";
