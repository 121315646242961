import React from "react";
import { connect } from "react-redux";
import {
    TStateMapper,
    TDispatchMapper,
} from "tsi-common-react/src/apps/reducers.interfaces";
import { IReduxState } from "tsi-common-react/src/apps/checkout/reducers.interfaces";
import { Loaders } from "./loaders";
import { Dispatchers } from "tsi-common-react/src/apps/checkout/dispatchers";
import { Actions } from "./actions";
import { defaults } from "tsi-common-react/src/apps/checkout/defaults";
import { FavoritesList } from "./FavoritesList";
import { LIST_FAVORITES } from "./constants";

interface IOwnProps {}

interface IReduxProps extends IReduxState {}

interface IDispatchProps {
    loaders: Loaders;
    dispatchers: Dispatchers;
    actions: Actions;
}

interface IProps extends IOwnProps, IReduxProps, IDispatchProps {}

interface IState {}

class FavoritesComponent extends React.Component<IProps, IState> {
    private getItemsLabel(qty: number) {
        return qty === 1 ? "item" : "items";
    }

    componentDidMount() {
        this.props.loaders.loadFavorites();
    }

    private buildFavoritesList() {
        const lines = this.props.data.wishlists[LIST_FAVORITES];
        if (!lines || !lines.length) {
            return (
                <React.Fragment>
                    <header>
                        <h1>
                            Your<span className="flourish">Favorites</span>
                        </h1>
                    </header>
                    <h3>You don&apos;t currently have any favorites.</h3>
                </React.Fragment>
            );
        }

        return (
            <React.Fragment>
                <header>
                    <h1>
                        Your<span className="flourish">Favorites</span>
                    </h1>
                    <h2>
                        ({lines.length} {this.getItemsLabel(lines.length)})
                    </h2>
                </header>
                <FavoritesList
                    lines={lines}
                    removeWishlistLine={(url) => {
                        return this.props.actions.removeWishlistLine(url);
                    }}
                />
            </React.Fragment>
        );
    }

    render() {
        return <React.Fragment>{this.buildFavoritesList()}</React.Fragment>;
    }
}

const mapStateToProps: TStateMapper<"checkout", IReduxProps, IOwnProps> = (
    rootState,
    ownProps,
) => {
    const state = rootState.checkout || defaults;
    return {
        ...state,
        ...ownProps,
    };
};

const mapDispatchToProps: TDispatchMapper<IDispatchProps> = (dispatch) => {
    const dispatchers = new Dispatchers(dispatch);
    const loaders = new Loaders(dispatchers);
    const actions = new Actions(loaders);
    return {
        dispatchers: dispatchers,
        loaders: loaders,
        actions: actions,
    };
};

export const Favorites = connect(
    mapStateToProps,
    mapDispatchToProps,
)(FavoritesComponent);
