import React from "react";
import { connect } from "react-redux";
import { IOptionCode } from "tsi-common-react/src/models/catalogue.interfaces";
import { IReduxState } from "../../reducers.interfaces";
import { buildGallery } from "../../helpers/gallery";
import { registerEventListeners_DetailSliderHeaders } from "../../helpers/detail-slider";

interface IOwnProps {
    attributeCode: IOptionCode;
    attributeValue: string;
    content: string;
}

interface IReduxProps {
    selectedValues: IReduxState["configurator"]["ui"]["optionValues"];
}

type IProps = IOwnProps & IReduxProps;

interface IState {}

class PDPSelectedVariantConditionalBlockComponent extends React.Component<
    IProps,
    IState
> {
    componentDidMount() {
        buildGallery();
        registerEventListeners_DetailSliderHeaders();
    }

    componentDidUpdate() {
        buildGallery();
        registerEventListeners_DetailSliderHeaders();
    }

    render() {
        // If the currently selected value of the configured attribute doesn't match the desired value, don't render anything
        const selectedValue =
            this.props.selectedValues[this.props.attributeCode];
        if (selectedValue !== this.props.attributeValue) {
            return null;
        }
        // If it DOES match, render the content block's HTML
        const markup = { __html: this.props.content };
        // And re-enable event listeners
        // buildGallery();
        // registerEventListeners_DetailSliderHeaders();
        return <div dangerouslySetInnerHTML={markup}></div>;
    }
}

const mapStateToProps = (state: IReduxState, ownProps: IOwnProps): IProps => {
    return { ...ownProps, selectedValues: state.configurator.ui.optionValues };
};

export const PDPSelectedVariantConditionalBlock = connect(mapStateToProps)(
    PDPSelectedVariantConditionalBlockComponent,
);
